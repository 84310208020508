<template>
  <div class="Header">
    <!-- Top -->
    <div class="Top">
      <div class="LeftSide">
        <span class="Title">
          {{ projectAddress.address }}, {{ projectAddress.postalCode }} {{ projectAddress.city }}
        </span>
        <span v-if="caseAndSampleNumbers">{{ caseAndSampleNumbers }}</span>
      </div>
      <div class="RightSide">
        <div
          v-if="currentScreening && currentScreeningData"
          class="ChipList">
          <Chip
            :text="currentScreeningData.getStatus().text"
            :icon="LightningIcon" />
          <Chip
            v-if="
              isTestDataUploaded &&
                currentScreeningTestUploadStatus.samples === currentScreeningTestUploadStatus.done
            "
            :text="mixWB('TEST_RESULTS_UPLOADED')"
            :icon="TestTupeIcon" />
          <Chip
            v-if="currentScreeningData.isStarted"
            :text="currentScreeningData.getScreeningTimeUsed()"
            :icon="StopWatchIcon" />
          <Chip
            v-if="currentScreeningData.isArchived"
            :text="mixWB('ARCHIVED')"
            :icon="ArchivedIcon" />
          <Chip
            :text="mixGetDate(metaData.createdAt, 'LL')"
            :icon="CalendarIcon" />
          <Chip
            v-if="screener"
            :text="screener.fullName"
            :icon="UserIcon" />
        </div>

        <div
          v-if="currentScreeningData"
          class="ButtonsList">
          <div
            class="TestDataUploadWrap">
            <input
              ref="testDataInput"
              type="file"
              accept=".xls,.xlsx"
              @change="onTestDataFileSelected" />
            <Button
              :text="testResultUploadText"
              :isDisabled="!currentScreeningData.isCompleted"
              :fullWidth="false"
              size="small"
              @button-click="onUploadTestDataClick" />
          </div>
          <Button
            v-if="showRequisitionButton"
            :path="{
              name: 'Requisition',
              params: { screeningID: this.currentScreening.id },
            }"
            :text="mixWB('REQUISITION')"
            size="small"
            :isDisabled="!currentScreeningData.isCompleted"
            :fullWidth="false" />
          <Button
            v-if="showReportButton"
            :path="reportPath"
            :text="mixWB('REPORT_2')"
            size="small"
            :isDisabled="
              currentScreeningTestUploadStatus.samples !== currentScreeningTestUploadStatus.done
            "
            :fullWidth="false" />
          <Button
            v-if="showTenderButton"
            :path="tenderPath"
            :text="mixWB('TENDER')"
            size="small"
            :isDisabled="
              currentScreeningTestUploadStatus.samples !== currentScreeningTestUploadStatus.done
            "
            :fullWidth="false" />
          <Button
            v-if="showButton"
            :text="mixWB('ARCHIVE')"
            size="small"
            :fullWidth="false"
            @button-click="onArchiveClick" />
          <Button
            v-if="currentUser.isAdmin"
            :text="mixWB('SHOW_IN_BACKEND')"
            size="small"
            :fullWidth="false"
            @button-click="onShowInBackendClick" />
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <TabNavigation
      v-if="currentScreening.id"
      :navigationItems="navigationItems" />

    <Dialog
      :isShowing="!!testDataUploadStatus"
      :useComponent="TestDataUploadStatusModal"
      :componentProps="{
        uploadStatus: testDataUploadStatus,
      }"
      :easyClose="false"
      @close="onTestDataUploadStatusClose" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Screening from '@/globals/javascript/models/Screening'
import Button from '@/components/Buttons/Button.vue'
import Chip from '@/components/Chips/Chip'
import LightningIcon from '@/assets/svg/lightning.svg?inline'
import StopWatchIcon from '@/assets/svg/stop-watch.svg?inline'
import ArchivedIcon from '@/assets/svg/archived-screenings.svg?inline'
import CalendarIcon from '@/assets/svg/calendar.svg?inline'
import TestTupeIcon from '@/assets/svg/test-tube.svg?inline'
import UserIcon from '@/assets/svg/user.svg?inline'
import Dialog from '@/components/Modals/Dialog'
import TestDataUploadStatusModal from '@/components/ResultItems/TestDataUploadStatusModal'
import TabNavigation from '@/components/TabNavigation.vue'

export default {
  name: 'Header',
  data() {
    return {
      TestDataUploadStatusModal,
      LightningIcon,
      StopWatchIcon,
      ArchivedIcon,
      CalendarIcon,
      TestTupeIcon,
      UserIcon,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'currentScreeningData',
      'metaData',
      'allUsers',
      'currentRoute',
      'currentUser',
      'caseNumber',
      'personalSamplingID',
      'testDataUploadStatus',
      'isTestDataUploaded',
      'currentScreeningTestUploadStatus',
    ]),
    navigationItems() {
      return [
        {
          title: this.mixWB('OVERVIEW'),
          pathName: 'ResultOverview',
          params: { screeningID: this.currentScreening.id },
          isDisabled: false,
        },
        {
          title: this.mixWB('SAMPLES'),
          pathName: 'ResultSamples',
          params: { screeningID: this.currentScreening.id },
          isDisabled: false,
        },
        {
          title: this.mixWB('WASTE_DISTRIBUTION'),
          pathName: 'WasteDistribution',
          params: { screeningID: this.currentScreening.id },
          isDisabled: this.currentScreeningTestUploadStatus.samples
          !== this.currentScreeningTestUploadStatus.done,
        },
      ]
    },
    projectAddress() {
      if (!this.currentScreeningData) {
        return {}
      }

      return this.currentScreeningData.address
    },
    caseAndSampleNumbers() {
      if (!this.personalSamplingID) {
        return this.caseNumber
      }

      return `${ this.caseNumber } // ${ this.personalSamplingID }`
    },
    screener() {
      return this.allUsers.find((x) => x.id === this.currentScreeningData.userID)
    },
    testResultUploadText() {
      return `${
        this.mixWB('UPLOAD_TEST_RESULTS')
      } (${
        this.currentScreeningTestUploadStatus.done
      }/${
        this.currentScreeningTestUploadStatus.samples
      })`
    },
    showRequisitionButton() {
      if (!this.currentScreeningData || !this.currentScreeningData.appVersion) {
        return false
      }

      return this.mixCompareVersionsSameOrNewer({
        versionA: this.currentScreeningData.appVersion,
        versionB: '1.8.2',
      })
    },
    showReportButton() {
      if (!this.currentScreeningData || !this.currentScreeningData.appVersion) {
        return false
      }
      return true
    },
    showTenderButton() {
      if (!this.currentScreeningData || !this.currentScreeningData.appVersion) {
        return false
      }
      return true
    },
    showButton() {
      if (!this.currentScreeningData || this.currentScreeningData.isArchived) {
        return false
      }

      return true
    },
    reportPath() {
      const showNewReport = this.mixCompareVersionsSameOrNewer(
        {
          versionA: this.currentScreeningData.appVersion,
          versionB: '1.14.0',
        },
      )

      // Current report
      if (showNewReport) {
        return {
          name: 'Report',
          params: { screeningID: this.currentScreening.id },
        }
      }

      // Pre 1.14.0 report
      return {
        name: 'ReportPre1.14.0',
        params: { screeningID: this.currentScreening.id },
      }
    },
    tenderPath() {
      const showNewReport = this.mixCompareVersionsSameOrNewer(
        {
          versionA: this.currentScreeningData.appVersion,
          versionB: '1.14.0',
        },
      )

      // Current report
      if (showNewReport) {
        return {
          name: 'Tender',
          params: { screeningID: this.currentScreening.id },
        }
      }

      // Pre 1.14.0 report
      return {
        name: 'TenderPre1.14.0',
        params: { screeningID: this.currentScreening.id },
      }
    },
  },
  methods: {
    onUploadTestDataClick() {
      this.$refs.testDataInput.click()
    },
    onTestDataFileSelected(event) {
      const { files } = event.target

      if (!files || !files[0]) {
        return
      }

      if (this.currentRoute.name !== 'ResultSamples') {
        this.$router.push({ name: 'ResultSamples', params: { screeningID: this.$route.params.screeningID } })
      }
      this.$store.dispatch('arrangeData', { file: files[0] })
    },
    onTestDataUploadStatusClose() {
      this.$store.dispatch('updateDataUploadStatus', '')
      this.$refs.testDataInput.value = ''
    },
    onArchiveClick() {
      this.$store.dispatch('updateScreening', {
        setAsArchived: true,
      })
    },
    onShowInBackendClick() {
      Screening.showInBackend(this.currentScreening.id)
    },
  },
  components: {
    Chip,
    Button,
    Dialog,
    TabNavigation,
  },
}
</script>

<style lang="stylus" scoped>
  .Header
    display block
    padding 20px 20px 0px
    margin-bottom 30px
    background-color $color_grey_lightestest
    border-bottom 1px solid $color_grey_lighter

  .Top
    display flex
    justify-content space-between
    +below($tablet)
      display block

  .LeftSide
    flex-grow 2
    min-width 50%
    span
      display block
    .Title
      font-size 2.5rem
    +below($tablet)
      margin-bottom 10px
      .Title
        font-size 1.5rem
        font-weight bold

  .RightSide
    flex-shrink 0
    display flex
    flex-direction column
    align-items flex-end
    max-width 50%
    +below($tablet)
      max-width 100%
      display block

  .ChipList
    display flex
    flex-wrap wrap
    justify-content flex-end
    margin 0 -7.5px 10px
    max-width 600px
    > div
      margin 0 2.5px 5px
    +below($tablet)
      justify-content flex-start
      margin-bottom 15px
      flex-wrap wrap

  .ButtonsList
    display flex
    flex-wrap wrap
    justify-content flex-end
    margin-left -10px
    margin-right -10px
    >>> .ButtonWrap
      display inline-block
      margin 0px 5px 10px
    .TestDataUploadWrap
      margin-bottom 0
    +below($tablet)
      justify-content flex-start

  .TestDataUploadWrap
    input
      display none
</style>
