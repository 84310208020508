var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Header"},[_c('div',{staticClass:"Top"},[_c('div',{staticClass:"LeftSide"},[_c('span',{staticClass:"Title"},[_vm._v(" "+_vm._s(_vm.projectAddress.address)+", "+_vm._s(_vm.projectAddress.postalCode)+" "+_vm._s(_vm.projectAddress.city)+" ")]),(_vm.caseAndSampleNumbers)?_c('span',[_vm._v(_vm._s(_vm.caseAndSampleNumbers))]):_vm._e()]),_c('div',{staticClass:"RightSide"},[(_vm.currentScreening && _vm.currentScreeningData)?_c('div',{staticClass:"ChipList"},[_c('Chip',{attrs:{"text":_vm.currentScreeningData.getStatus().text,"icon":_vm.LightningIcon}}),(
            _vm.isTestDataUploaded &&
              _vm.currentScreeningTestUploadStatus.samples === _vm.currentScreeningTestUploadStatus.done
          )?_c('Chip',{attrs:{"text":_vm.mixWB('TEST_RESULTS_UPLOADED'),"icon":_vm.TestTupeIcon}}):_vm._e(),(_vm.currentScreeningData.isStarted)?_c('Chip',{attrs:{"text":_vm.currentScreeningData.getScreeningTimeUsed(),"icon":_vm.StopWatchIcon}}):_vm._e(),(_vm.currentScreeningData.isArchived)?_c('Chip',{attrs:{"text":_vm.mixWB('ARCHIVED'),"icon":_vm.ArchivedIcon}}):_vm._e(),_c('Chip',{attrs:{"text":_vm.mixGetDate(_vm.metaData.createdAt, 'LL'),"icon":_vm.CalendarIcon}}),(_vm.screener)?_c('Chip',{attrs:{"text":_vm.screener.fullName,"icon":_vm.UserIcon}}):_vm._e()],1):_vm._e(),(_vm.currentScreeningData)?_c('div',{staticClass:"ButtonsList"},[_c('div',{staticClass:"TestDataUploadWrap"},[_c('input',{ref:"testDataInput",attrs:{"type":"file","accept":".xls,.xlsx"},on:{"change":_vm.onTestDataFileSelected}}),_c('Button',{attrs:{"text":_vm.testResultUploadText,"isDisabled":!_vm.currentScreeningData.isCompleted,"fullWidth":false,"size":"small"},on:{"button-click":_vm.onUploadTestDataClick}})],1),(_vm.showRequisitionButton)?_c('Button',{attrs:{"path":{
            name: 'Requisition',
            params: { screeningID: this.currentScreening.id },
          },"text":_vm.mixWB('REQUISITION'),"size":"small","isDisabled":!_vm.currentScreeningData.isCompleted,"fullWidth":false}}):_vm._e(),(_vm.showReportButton)?_c('Button',{attrs:{"path":_vm.reportPath,"text":_vm.mixWB('REPORT_2'),"size":"small","isDisabled":_vm.currentScreeningTestUploadStatus.samples !== _vm.currentScreeningTestUploadStatus.done,"fullWidth":false}}):_vm._e(),(_vm.showTenderButton)?_c('Button',{attrs:{"path":_vm.tenderPath,"text":_vm.mixWB('TENDER'),"size":"small","isDisabled":_vm.currentScreeningTestUploadStatus.samples !== _vm.currentScreeningTestUploadStatus.done,"fullWidth":false}}):_vm._e(),(_vm.showButton)?_c('Button',{attrs:{"text":_vm.mixWB('ARCHIVE'),"size":"small","fullWidth":false},on:{"button-click":_vm.onArchiveClick}}):_vm._e(),(_vm.currentUser.isAdmin)?_c('Button',{attrs:{"text":_vm.mixWB('SHOW_IN_BACKEND'),"size":"small","fullWidth":false},on:{"button-click":_vm.onShowInBackendClick}}):_vm._e()],1):_vm._e()])]),(_vm.currentScreening.id)?_c('TabNavigation',{attrs:{"navigationItems":_vm.navigationItems}}):_vm._e(),_c('Dialog',{attrs:{"isShowing":!!_vm.testDataUploadStatus,"useComponent":_vm.TestDataUploadStatusModal,"componentProps":{
      uploadStatus: _vm.testDataUploadStatus,
    },"easyClose":false},on:{"close":_vm.onTestDataUploadStatusClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }